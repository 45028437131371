import { useEffect } from 'react';
import PropertyLayout from '../../form-builder/PropertyLayout';
import { EditorProps } from '../../form-builder/FormBuilderTypes';
import { useTranslation } from 'react-i18next';
import { Input } from '../../shared/form-control/Input';
import Checkbox, { SliderSize } from '../../shared/form-control/Checkbox';
import { FCWithChildren } from '../../../types/FCWithChildren';

export type QuizActionData = {
  points: number;
  correctAnswer: boolean | string; // depending on quiz type;
};

const QuizActionEditorBase: FCWithChildren<EditorProps> = (props) => {
  const { patchData, patchAction, action, children } = props;
  const { points = 1 } = action.data || {};
  const { t } = useTranslation('form-builder');

  useEffect(() => {
    if (!action.data || action.data.points !== undefined) {
      patchData({ points });
    }
  }, [action.data, patchData, points]);

  useEffect(() => {
    if (action.required === undefined) {
      patchAction({ required: 'true' });
    }
  }, [action.required, patchAction]);

  useEffect(() => {
    if (action.visible === undefined) {
      patchAction({ visible: 'true' });
    }
  }, [action.visible, patchAction]);

  return (
    <div>
      <div className="mt-2 flex items-center justify-between">
        <div>{t('action-properties.common.conditionals.required')}</div>
        <div>
          <Checkbox
            value={action.required === 'true'}
            onChange={(value) => patchAction({ required: value.toString() })}
            slider
            sliderSize={SliderSize.S}
          />
        </div>
      </div>
      {children}
      <PropertyLayout
        label={t('action-properties.quiz-base.point-system.label')}
        description={t('action-properties.quiz-base.point-system.description')}
      >
        <div className="ml-2 flex items-center gap-2">
          <div className="w-20">
            <Input type="number" value={points} onChange={(e) => patchData({ points: parseInt(e.target.value) })} />
          </div>
          <span>{t('action-properties.quiz-base.point-system.points')}</span>
        </div>
      </PropertyLayout>
    </div>
  );
};

export default QuizActionEditorBase;
