import { FC, useEffect } from 'react';
import { EditorProps } from '../../../form-builder/FormBuilderTypes';
import ActionEditorBase from '../ActionEditorBase';
import ConditionsProperties from '../../../form-builder/ConditionsProperties';
import QuizActionEditorBase from '../QuizActionEditorBase';
import PropertyLayout from '../../../form-builder/PropertyLayout';
import RadioButton from '../../../shared/form-control/RadioButton';
import { useTranslation } from 'react-i18next';

const TrueFalseEditor: FC<EditorProps> = (props) => {
  const { patchData, action } = props;
  const { t } = useTranslation('form-builder');
  const { correctAnswer = true.toString() } = action.data || {};

  useEffect(() => {
    if (!action.data || action.data.correctAnswer === undefined) {
      patchData({ correctAnswer });
    }
  }, [action.data, patchData, correctAnswer]);

  return (
    <div data-cy="true-false-editor">
      <ActionEditorBase {...props}>
        <QuizActionEditorBase {...props}>
          <PropertyLayout
            label={t('action-properties.true-false.correct-answer.label')}
            description={t('action-properties.true-false.correct-answer.description')}
          >
            <div className="ml-2 flex flex-col">
              <RadioButton
                value={correctAnswer === true.toString()}
                label={t('action-properties.true-false.correct-answer.true')}
                size="small"
                onChange={(value) => patchData({ correctAnswer: value.toString() })}
              />
              <RadioButton
                value={correctAnswer === false.toString()}
                label={t('action-properties.true-false.correct-answer.false')}
                size="small"
                onChange={(value) => patchData({ correctAnswer: (!value).toString() })}
              />
            </div>
          </PropertyLayout>
        </QuizActionEditorBase>
      </ActionEditorBase>
    </div>
  );
};

export default TrueFalseEditor;
